import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'fullName', sortable: true, label: 'Họ tên' },
    { key: 'phone', sortable: true, label: 'Số điện thoại' },
    { key: 'nicNumber', sortable: true, label: 'CMND/CCCD' },
    { key: 'gender', sortable: true, label: 'Giới tính' },
    { key: 'company', sortable: true, label: 'Công ty' },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const phone = ref(null);
  const name = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage], () => {
    console.log(currentPage, perPage);
    refetchData();
  });

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-customer/fetchCustomers', {
        limit: perPage.value,
        page: currentPage.value,
        phone: phone.value,
        name: name.value,
      })
      .then((response) => {
        const { data, meta } = response.data;

        callback(data);
        totalUsers.value = meta.total;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };
  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    refUserListTable,
    refetchData,
  };
}
