import axios from '@axios';
import { customerList } from '@/apis/apiCustomer';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, condittion) {
      return new Promise((resolve, reject) => {
        customerList(condittion)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/customers/${id}`)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
  },
};
